.table {
    width: 100%;
    border-collapse: collapse;
}

.table th, .table td {
    padding: 20px;
    text-align: left;
    max-width: 100%;
}

.header {
    background-color: #171A1A;
    font-weight: bold;
    color: orange;
}

.row:nth-child(even) {
    background-color: #24292B; /* Dark background for even rows */
}

.image {
    max-width: 90px;
}

.subText {
    font-size: 12px;
    color: lightgray;
}

.rejectButton {
    background-color: orange;
    font-weight: bold;
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    border: none;
    cursor: pointer;
}

.row:nth-child(odd) {
    background-color: #24292B; /* Slightly lighter for odd rows */
}

.cell {
    border-bottom: 1px solid #444; /* Subtle border between rows */
    color: white;
    font-size: 14px;
    font-weight: bold;
}

.infoText, .sub, .lightH, .guthaben {
    margin-bottom: 16px;
    line-height: 30px;
    font-weight: bold;
    color: white;
}

/* Adjust font sizes */
.infoText {
    font-size: 18px;
}

.sub, .lightH {
    font-size: 16px;
    color: orange;
}

.guthaben {
    font-size: 20px;
}

/* Media Query for devices with a width of up to 450px */
@media (max-width: 450px) {
    .table th, .table td {
        padding: 10px;
        font-size: 12px;
    }

    .image {
        max-width: 50px; /* Smaller images for smaller screens */
    }

    .rejectButton {
        padding: 5px; /* Smaller padding for buttons */
        font-size: 10px; /* Smaller font size for buttons */
    }

    .cell {
        font-size: 12px; /* Smaller font size for text */
    }

    .header {
        font-size: 12px; /* Smaller font size for headers */
    }

    .transfersList {
        width: 100%; /* Ensure the container fits the screen */
        overflow-x: auto; /* Enable horizontal scrolling if necessary */
    }

    .table {
        width: 100%; /* Ensure the table fits within the parent container */
        table-layout: fixed; /* Fix the table layout to fit the container */
    }

    .cell, .header {
        word-wrap: break-word; /* Allow text to wrap within the cell */
        text-align: center; /* Center align text for better mobile experience */
    }
}
