.card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  overflow: hidden;
  color: white; /* Text color white */
}

.transferButton {
  text-align: left;
}
.textContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 30px;
}

.numberBox {
  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent background for number */
  padding: 8px;
  border-radius: 4px;
  width: fit-content;
  gap: 10px;
}

.playerNumber {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff; /* White text */
}

.playerInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.playerName {
  font-size: 20px;
  font-weight: bold;
  line-height: initial;
  max-width: 180px;
  height: 49px;
  overflow: hidden;

  margin: 0;
}

.playerPosition {
  font-size: 14px;
  color: #b0b0b0; /* Default light grey text */
}

.playerSID {
  font-size: 14px;
  color: orange; /* Default light grey text */
}


.nationalityRow {
  display: flex;
  align-items: center;
  gap: 5px;
}

.flagImage {
  width: 20px;
  height: auto;
}

.eligible {
  color: green; /* Green for Spielberechtigt */
  font-weight: 800;
  font-size: 16px;

}

.suspended {
  color: red; /* Red for Gesperrt */
}

.waiting {
  color: yellow; /* Yellow for Warten */
}

.imageWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.playerImage {
  width: auto;
  height: 200px;
  object-fit: contain;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1a1a1a;
  padding: 8px;
  border-top: 1px solid #333;
}

.footerItem {
  font-size: 14px;
  font-weight: bold;
  color: #ffd700; /* Gold color */
  cursor: pointer;
}
