.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
    margin-bottom: 20px;
    background-color: #171A1A;


    &.light {
        background: var(--widget);
        box-shadow: 0 1px 8px rgba(110, 110, 110, .1);
    }

    &.dark {
        background: var(--border);
    }
}

.field {
    max-width: 220px !important;
    height: 264px !important;
    margin-top: 30px !important;

    // tablet portrait
    @media screen and (min-width: 768px) {
        margin-top: 10px;
    }
}

.day {
    color: orange;
}

.active {

}

.dress {
    height: 80px;
    width: 40px;
    object-fit: contain;
    background-color: white;
    padding: 5px;
}

.clublogo {
    height: 50px;
    object-fit: contain;
    width: 50px;
    background-color: white;
    padding: 5px;
}


.btnOrange {
    padding: 10px 30px;
    background-color: orange;
    font-weight: bold;
    color: black;
    border-radius: 5px;
    margin: 0px 10px;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover and state changes */

    &:hover {
        background-color: orangered;
        color: white; /* Change text color for selected state */

    }

    &.selected {
        background-color: red;
        color: white; /* Change text color for selected state */
    }

    &:disabled {
        background-color: #ccc; /* Gray background for disabled state */
        color: #666; /* Gray text color for disabled state */
        cursor: not-allowed; /* Optional: Change cursor for disabled state */
    }
}

.btnOrange:disabled {
    background-color: #ccc; /* Gray background for disabled state */
    color: #666; /* Gray text color for disabled state */
    cursor: not-allowed; /* Optional: Change cursor for disabled state */
}