.formContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align content to the left */
    gap: 20px;
    padding: 20px; /* Add some padding for better appearance */
}

.imagePreviewContainer {
    display: flex;
    justify-content: flex-start; /* Align image to the left */
    margin-bottom: 20px;
}

.imagePreview {
    max-width: 150px;
    max-height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.row {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Reduce gap for more compact appearance */
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align buttons to the left */
    gap: 10px;
    margin-top: 20px;
}

@media screen and (min-width: 414px) {
    .footer {
        flex-direction: row;
        justify-content: flex-start; /* Align buttons to the left for mobile */
    }
}

@media screen and (min-width: 768px) {
    .row {
        display: flex;
        flex-direction: column; /* Keep inputs in a column layout */
    }

    .footer {
        flex-direction: row;
        justify-content: flex-start; /* Align buttons to the left for larger screens */
    }
}
